<template>
    <div>
        <particles />
        <steps :active="2" />
        <div :style="{ width: `200px`, height: `250px` }" class="record_container">
            <div style="width:100%;height:75%;display:flex;justify-content:center;align-items:center;padding:15px 20px;box-sizing:border-box;">
                <div style="height:100%;width:70%;display:flex;justify-content:center;align-items:center">
                    <img v-if="cancel_flag" src="../assets/undo_record.png" style="height:100%;max-width:100%" />
                    <img v-else src="../assets/record.png" style="height:100%;max-width:100%" />
                </div>
                <div class="record_voice_container" v-if="record_state">
                    <div style="background:white;width:90%;height:5%;" v-show="random_voice >= 10" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:86%;height:5%;" v-show="random_voice >= 9" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:82%;height:5%;" v-show="random_voice >= 8" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:78%;height:5%;" v-show="random_voice >= 7" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:74%;height:5%;" v-show="random_voice >= 6" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:70%;height:5%;" v-show="random_voice >= 5" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:66%;height:5%;" v-show="random_voice >= 4" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:62%;height:5%;" v-show="random_voice >= 3" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:58%;height:5%;" v-show="random_voice >= 2" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:54%;height:5%;" v-show="random_voice >= 1" />
                    <div style="height:4.5%;" />
                    <div style="background:white;width:50%;height:5%;" v-show="random_voice >= 0" />
                </div>
            </div>
            <div style="height:25%;width:100%;display:flex;justify-content:center;align-items:center;color:white;line-height:24px;text-align:center;font-size:12px">
                <div style="background:rgba(20,40,40,0.6);border-radius:4px;padding:0 10px" v-if="!record_state">长按下方按钮进行录音</div>
                <div style="background:rgba(240,20,20,0.6);border-radius:4px;padding:0 10px" v-else-if="cancel_flag">松开取消，下滑继续</div>
                <div style="background:rgba(20,20,20,0.6);border-radius:4px;padding:0 10px" v-else>手指上滑，取消录音</div>
            </div>
        </div>
    
        <van-button :type="main_type" @touchstart="start" @touchmove="move" @touchend="end" class="main_button" :loading="record_state" :loading-text="main_text">{{ main_text }}</van-button>
        <van-popup v-model:show="show_tip" round position="bottom" style="height:30%;display:flex;flex-direction:column;justify-content:space-between;padding:25px 0" duration="0.15">
            <strong style="text-align:center;font-size:20px">注 意 事 项</strong>
            <span style="padding:30px;text-indent:2em;font-size:14px;line-height:16px;color:#333">为确保结果准确，可朗读“中医大脑，为您的健康保驾护航”，并录制在3秒以上！</span>
            <van-button @click="show_tip = false" type="primary" style="width:90%;margin:0 auto">我知道了</van-button>
        </van-popup>
    </div>
</template>

<script>
import particles from "../components/particles";
import steps from "../components/steps";
import moment from "moment";
export default {
    components: {
        particles,
        steps,
    },
    data() {
        return {
            wx: new this.$common.WX(),
            record_state: false,
            record_start_time: null,
            record_start_touch_Y: 0,
            main_text: "按下开始录音",
            main_type: "primary",
            cancel_flag: false,
            random_voice: 0,
            analyse_flag: false,
            show_tip: false,
            next: false,
            media_id: "",
            src: "",
        };
    },
    methods: {
        start(e) {
            if (this.next) {
                this.$api("analyseVoice", { media_id: this.media_id });
                // this.$router.push('/step4');
                const type = this.$route.query.type;
                this.$router.push({
                    path: "/step4",
                    query: { type: type },
                });
            } else {
                e.preventDefault();
                if (this.record_state) return;
                this.next = false;
                this.record_state = true;
                this.cancel_flag = false;
                this.random_voice = 0;
                this.record_start_touch_Y = e.changedTouches[0].clientY;
                this.record_start_time = new moment();
                this.main_text = "请稍等...";
                this.main_type = "primary";
                this.wx
                    .startRecord()
                    .then(() => {
                        this.main_text = "正在录音...";
                    })
                    .catch((res) => {
                        if (
                            res.errMsg &&
                            res.errMsg.indexOf("startRecord:fail") != -1
                        ) {
                            this.main_text = res.errMsg.slice(
                                res.errMsg.indexOf("startRecord:fail") + 16
                            );
                        } else {
                            this.main_text = "录音失败，请重试";
                        }
                        setTimeout(() => {
                            this.main_type = "primary";
                            this.main_text = "按下开始录音";
                        }, 2000);
                        this.main_type = "danger";
                        this.record_state = false;
                    });
            }
        },
        move(e) {
            this.cancel_flag =
                e.changedTouches[0].clientY < this.record_start_touch_Y * 0.65;
        },
        end(e) {
            if (!this.record_state) return;
            if (this.cancel_flag) {
                this.cancel_flag = false;
                this.wx.stopRecord();
                this.main_type = "warning";
                this.main_text = "已取消录制";
                this.record_state = false;
                setTimeout(() => {
                    this.main_type = "primary";
                    this.main_text = "按下开始录音";
                }, 800);
                return;
            }
            if (new moment().diff(this.record_start_time) < 1500) {
                this.wx.stopRecord();
                this.main_type = "warning";
                this.main_text = "录音时间太短，请重新录制";
                this.record_state = false;
                setTimeout(() => {
                    this.main_type = "primary";
                    this.main_text = "按下开始录音";
                }, 800);
                return;
            }
            this.wx
                .stopRecord()
                .then(async (local_id) => {
                    this.src = local_id;
                    this.record_state = false;
                    this.main_text = "录音完成，正在上传...";
                    this.main_type = "success";
                    return this.wx.uploadRecord(local_id);
                })
                .then(async (server_id) => {
                    this.main_text = "上传完成，正在分析...";
                    this.media_id = server_id;
                    return this.$api("checkVoice", { media_id: server_id });
                })
                .then((res) => {
                    if (res.result) {
                        this.main_text = "分析完成，进入下一步";
                        this.next = true;
                    } else {
                        this.main_text = "音频不合格，请重新录制";
                        this.main_type = "danger";
                    }
                })
                .catch((res) => {
                    this.main_text = "录音失败，请重试";
                    this.main_type = "danger";
                    this.record_state = false;
                });
        },
    },
    mounted() {
        let f = (i) => {
            this.random_voice += i;
            if (this.random_voice == 0) {
                setTimeout(f, 100, 1);
            } else if (this.random_voice == 10) {
                setTimeout(f, 100, -1);
            } else if (
                Math.abs(this.random_voice - 5) ** 3 * 0.008 >
                Math.random()
            ) {
                setTimeout(f, 100, -i);
            } else {
                setTimeout(f, 100, i);
            }
        };
        f(1);
        this.show_tip = true;
    },
};
</script>

<style scoped>
.main_button {
    position: fixed;
    bottom: 30px;
    left:10%;
    display: block;
    width: 80%;
    transition: all 0.35s;
}
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.record_container {
    background: rgba(30, 30, 30, 0.6);
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-in-out;
}
.record_voice_container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
</style>
